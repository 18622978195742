import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Label, ModalFooter } from 'reactstrap'
import Button from '@mui/material/Button'
import { Modal } from 'semantic-ui-react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import ReschedulingFdbkForms from './ReschedulingFdbkForms.jsx'
import PreviewFeedback from './PreviewFeedback.jsx'

import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import moment from 'moment'

import { Loader, Segment } from 'semantic-ui-react'


import {
  startFetchCourse,
  AddFeedbackQuestion,
  startFetchBatchCourse,
  startFetchBatchCourseChapters,
  StartFetchCourseFdbk,
  startFetchFeedbackScale,
  fetchFeedbackSection,
  surveyFdbkPublishLogs
} from '../../actions/courseAction.js'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const periodicity = {
  1: 'Daily',
  2: "Weekly",
  3: "Monthly",
  4: "Quaterly"
}

function RescheduleFeedbackForms() {
  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [loading, setLoading] = useState(false)

  const [coursePageList, setCoursePageList] = useState([])
  const [selectedCoursePage, setSelectedCoursePage] = useState({})

  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})

  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  const [feedbackTypeList, setFeedbackTypeList] = useState([])
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const [FeedbackArrayList, setFeedbackArrayList] = useState([])
  const [feedbackPublishDetails, setFeedbackPublishDetails] = useState([])
  const [showPreviewFeedback, setShowPreviewFeedback] = useState(false)
  const [sectionArr, setSectionArr] = useState([])
  const [answerTemplateNameMap, setAnswerTemplateNameMap] = useState({})
  const [showFdbkLogs, setShowFdbkLogs] = useState(false)
  const [selectedFeedbackPublishDeatail, setSelectedFeedbackPublishDeatail] = useState({})
  const [selectedFeedbackPublishDeatailog, setSelectedFeedbackPublishDeatailog] = useState([])



  useEffect(() => {
    ; (async () => {
      setLoading(true)

      let currentUrl = window.location.href
      let urlParams = new URLSearchParams(currentUrl.split('?')[1])
      let crs_id = urlParams.get('crs_id')
      let crs_pg_id = urlParams.get('crs_pg_id')
      let res = await startFetchCourse()
      setCoursePageList([...res.data.courses])

      if (crs_pg_id && crs_id && res.data && res.data.courses) {
        let crs = res.data.courses.filter((crs_pg_obj) => crs_pg_obj._id == crs_pg_id)

        if (crs.length > 0) {
          setSelectedCoursePage({ ...crs[0] })
        }

        let data = {
          crs_id: crs_pg_id,
          limit: 100,
          skip: 0,
        }
        let batch_list = await startFetchBatchCourse(data)
        if (batch_list) {
          setCourseList([...batch_list.data.slicedCourses])
          setChapterList([])
          setFeedbackTypeList([])
          setSelectedFeedbackType('')
          setSelectedChapter({})
          setSelectedCourse({})
          setFeedbackArrayList([])

          let batch = batch_list.data.slicedCourses.filter((batch_obj) => batch_obj._id == crs_id)
          if (batch.length > 0) {
            setSelectedCourse({ ...batch[0] })
          }

          console.log(batch)
          let res = await startFetchBatchCourseChapters({
            crs_pg_id: crs_pg_id,
            crs_id: crs_id,
          })

          if (res.data) {
            setChapterList([...res.data.course_chapters])
            setFeedbackTypeList([...res.data.feedback_type])
            setSelectedChapter({})
            setFeedbackArrayList([])
            setSelectedFeedbackType('Survey Feedback')


            res = await StartFetchCourseFdbk(
              crs_pg_id,
              crs_id,
              "Survey Feedback",
            )
            console.log({ res })
            if (res.data) {
              setFeedbackArrayList([...res.data.course_quizzes])
              setFeedbackPublishDetails([...res.data.feedback_publish_detail])

            }
          }


        }


      }


      setLoading(false)

    })()
  }, [])

  let handleSelectCoursePage = async (crs) => {
    setLoading(true)
    console.log(crs)
    setSelectedCoursePage({ ...crs })
    console.log({ selectedCoursePage })
    let coursePage = crs
    if (coursePage._id) {
      let data = {
        crs_id: coursePage._id,
        limit: 100,
        skip: 0,
      }
      let batch_list = await startFetchBatchCourse(data)

      if (batch_list) {
        setCourseList([...batch_list.data.slicedCourses])
        setChapterList([])
        setFeedbackTypeList([])
        setSelectedFeedbackType('')
        setSelectedChapter({})
        setSelectedCourse({})
        setFeedbackArrayList([])
        setLoading(false)

      }


    }

  }

  let handleSelectChapter = async (chapter) => {
    setLoading(true)

    console.log(chapter)
    setSelectedChapter({ ...chapter })
    if (chapter) {
      let res = await StartFetchCourseFdbk(
        selectedCoursePage._id,
        selectedCourse._id,
        selectedFeedbackType,
        chapter._id
      )
      console.log({ res })
      if (res.data) {
        setFeedbackArrayList([...res.data.course_quizzes])
        setFeedbackPublishDetails([...res.data.feedback_publish_detail])
      }
    }
    setLoading(false)

  }
  let handleSelectFeedbackType = async (feedback) => {
    setLoading(true)
    console.log(feedback)

    setSelectedFeedbackType(feedback)
    let res = await StartFetchCourseFdbk(
      selectedCoursePage._id,
      selectedCourse._id,
      feedback,
      selectedChapter._id
    )
    console.log({ res })
    if (res.data) {
      if(res.data.course_quizzes){
        setFeedbackArrayList([...res.data.course_quizzes])

      }else{
        setFeedbackArrayList([])

      }
      if(res.data.feedback_publish_detail){
        setFeedbackPublishDetails([...res.data.feedback_publish_detail])

      }else{
        setFeedbackPublishDetails([])

      }

    }
    setLoading(false)

  }
  let handleSelectCourse = async (batch) => {
    setLoading(true)

    console.log(batch)
    setSelectedCourse({ ...batch })
    let course = batch

    let res = await startFetchBatchCourseChapters({
      crs_pg_id: course.crs_pg_id,
      crs_id: course._id,
    })

    if (res.data) {
      setChapterList([...res.data.course_chapters])
      setFeedbackTypeList([...res.data.feedback_type])
      setSelectedChapter({})
      setFeedbackArrayList([])
      setSelectedFeedbackType('')
    }
    setLoading(false)
  }

  let handleShowFeedbackPublisLogs = async (data) => {
    setLoading(true)
    setSelectedFeedbackPublishDeatail({ ...data })
    let res = await surveyFdbkPublishLogs(selectedCoursePage._id,
      selectedCourse._id, data)
    if (res && res.data && res.data.feedback_publish_detail.fdbkPublishStatusLogs) {
      setSelectedFeedbackPublishDeatailog([...res.data.feedback_publish_detail.fdbkPublishStatusLogs])

    }

    setShowFdbkLogs(true)
    setLoading(false)

  }

  let handleShowPreviewFeeback = async (fdbk) => {
    console.log({ fdbk })
    setLoading(true)
    let res = await startFetchFeedbackScale()
    if (res.data && res.data.feedback_scale) {
      setAnswerTemplateNameMap({ ...res.data.answer_template_map })
    }
    let section = await fetchFeedbackSection(selectedCoursePage._id, fdbk)
    if (section && section.data && section.data.find_section) {
      setSectionArr([...section.data.find_section])
    }
    setShowPreviewFeedback(true)
    setLoading(false)
  }

  return (
    <div style={{ backgroundColor: 'rgba(37, 92, 168, 0.05)', height: '100vh', overflowX: "hidden" }} className='feedback-management'>
      <FeedbackDashboard />
      {!loading && <div className="side-bar-tab-view">
        <div style={{ display: "flex", justifyContent: "flex-start", gap: "24px" }}>
          <div style={{ width: "200px" }}>
            <Autocomplete
              style={{ width: "200px" }}
              className="select-course"
              disableClearable
              value={selectedCoursePage.name ? selectedCoursePage.name : 'Select a Course'}
              onChange={(e, crs) => {
                handleSelectCoursePage(crs)
              }}

              getOptionLabel={(course) => {
                return course.name || course
              }}
              getOptionSelected={(option, test) => option.name == test}
              options={coursePageList}
              disabled={coursePageList.length === 0 ? true : false}
              renderInput={(params) => {
                return (
                  <TextField
                    label={coursePageList.length == 0 ? 'Loading...' : ''}
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )
              }}
            />
          </div>
          <div style={{ width: "200px" }}>

            {selectedCoursePage && selectedCoursePage._id && (
              // <FormControl sx={{ m: 1, width: 200 }}>
              //   <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
              //   <Select
              //     labelId="demo-multiple-chip-label"
              //     id="demo-multiple-chip"
              //     value={selectedCourse}
              //     onChange={handleSelectCourse}
              //     input={
              //       <OutlinedInput id="select-multiple-chip" label="Select Batch" />
              //     }
              //     renderValue={(selected) => {
              //       return selected.btch_name
              //     }}
              //     MenuProps={MenuProps}
              //   >
              //     {courseList.map((course) => (
              //       <MenuItem key={course._id} value={course}>
              //         {course.btch_name}
              //       </MenuItem>
              //     ))}
              //   </Select>
              // </FormControl>

              <Autocomplete
                style={{ width: "200px" }}
                className="select-course"
                disableClearable
                value={selectedCourse.btch_name ? selectedCourse.btch_name : 'Select a Batch'}
                onChange={(e, batch) => {
                  handleSelectCourse(batch)
                }}

                getOptionLabel={(batch) => {
                  return batch.btch_name || batch
                }}
                getOptionSelected={(option, test) => option.name == test}
                options={courseList}
                disabled={courseList.length === 0 ? true : false}
                renderInput={(params) => {
                  return (
                    <TextField
                      label={courseList.length == 0 ? 'Loading...' : ''}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />
            )}
          </div>
          <div style={{ width: "200px" }}>

            {selectedCourse && selectedCourse._id && (

              <Autocomplete
                style={{ width: "200px" }}
                className="select-course"
                disableClearable
                value={selectedFeedbackType ? selectedFeedbackType : 'Select a Feedback Type'}
                onChange={(e, feedback) => {
                  handleSelectFeedbackType(feedback)
                }}


                getOptionLabel={(feedback) => {
                  return feedback
                }}
                getOptionSelected={(option, test) => option.name == test}
                options={feedbackTypeList}
                disabled={feedbackTypeList.length === 0 ? true : false}
                renderInput={(params) => {
                  return (
                    <TextField
                      label={feedbackTypeList.length == 0 ? 'Loading...' : ''}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />

            )}
          </div>
          <div style={{ width: "200px" }}>

            {selectedCourse &&
              selectedCourse._id &&
              selectedFeedbackType != 'Survey Feedback' && (
                <Autocomplete
                  className="select-course"
                  style={{ width: "200px" }}
                  disableClearable
                  value={selectedChapter.nm ? selectedChapter.nm : 'Select a Chapter'}
                  onChange={(e, chapter) => {
                    handleSelectChapter(chapter)
                  }}

                  getOptionLabel={(chapter) => {
                    return chapter.nm || chapter
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={chapterList}
                  disabled={chapterList.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={chapterList.length == 0 ? 'Loading...' : ''}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              )}
          </div>

        </div>

        <hr></hr>
        <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid', overflowX: "auto" }} >
          <table class="table table-bordered" style={{ marginBottom: "0rem" }}>
            <thead>
              <tr className='tr-layout'  style={{ textAlign: 'center'}}>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "160px" }}
                >
                  Feedback Name {selectedFeedbackType == 'Survey Feedback' &&<Tooltip style={{padding:"3px"}}
                    arrow
                    title={<Typography sx={{ fontSize: "12px" }}>
                      Click on the feedback name to view the details of published survey feedback.<br />
                      **Note - Only recurring survey feedback publish details will be visible.
                    </Typography>}
                  >
                    <IconButton sx={{ m: -0.9 }}>
                      <InfoIcon  />
                    </IconButton>
                  </Tooltip>}
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "190px" }}
                >
                  Feedback Test Name   <Tooltip style={{padding:"3px"}}
                    arrow
                    title={<Typography sx={{ fontSize: "12px" }}>
                      Click on the feedback test name to view the feedback form.  <br />
                      **Note - Feedback forms created on the Quiz Dashboard cannot be viewed.
                    </Typography>}
                  >
                    <IconButton sx={{ m: -0.9 }}>
                      <InfoIcon  />
                    </IconButton>
                  </Tooltip>
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "70px" }}
                >
                  Program
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "50px" }}
                >
                  Batch
                </th>
                {selectedFeedbackType != 'Survey Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: ' -webkit-center', minWidth: "70px" }}
                >
                  Chapter
                </th>}
                {selectedFeedbackType == 'Content Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: ' -webkit-center', minWidth: "70px" }}
                >
                  Content Name
                </th>}

                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "120px" }}
                >
                  Created On
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "250px" }}
                >
                  Feedback Completion %  <span><Tooltip style={{padding:"3px"}}
                    title={
                      <Typography sx={{ fontSize: "12px" }}>
                        Feedback completion percentage is calculated as the average completion rate of all released feedbacks. <br />
                        Each feedback's completion rate is computed as: <br />
                        <strong>(Number of Students Who Gave Feedback / Total Number of Students in the Batch) × 100</strong> <br />
                        The overall percentage is then averaged across all released feedbacks using: <br />
                        <strong>Σ (Completion Percentage of Each Released Feedback) / Total Number of Released Feedbacks</strong>
                      </Typography>
                    }

                    arrow
                  >
                    <IconButton sx={{ m: -0.9 }}>
                      <InfoIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip></span>
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "150px" }}
                >
                  Feedback Type
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "200px" }}
                >
                  Feedback Reminder Type
                </th>
                {selectedFeedbackType == 'Survey Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "200px" }}
                >
                  Feedback Publish
                </th>}
                {selectedFeedbackType == 'Survey Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth: "200px" }}
                >
                  Reschedule Feedback
                </th>}

              </tr>
            </thead>

            <tbody>
              {FeedbackArrayList && selectedFeedbackType != 'Survey Feedback' &&
                FeedbackArrayList.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="table-cell">{data.nm}</td>
                      {!data.is_old_feedback ? <td className="table-cell"> <Button sx={{ textTransform: 'none' }} onClick={() => { handleShowPreviewFeeback(data.tid) }}> {data.test_name}</Button></td> : <td className="table-cell"> {data.test_name}</td>}

                      <td className="table-cell">{selectedCourse.name}</td>
                      <td className="table-cell">{selectedCourse.btch_name}</td>
                      {selectedFeedbackType == 'Module Feedback' && <td className="table-cell">
                        {data.name ? data.name : '-'}
                      </td>}
                      {selectedFeedbackType == 'Content Feedback' && <td className="table-cell">
                        {data.ch_nm ? data.ch_nm : '-'}
                      </td>}
                      {selectedFeedbackType == 'Content Feedback' && <td className="table-cell">
                        {data.name ? data.name : '-'}
                      </td>}

                      <td className="table-cell">
                        {moment(data.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </td>

                      <td className="table-cell">{data.comp_perc ? parseFloat(data.comp_perc.toFixed(2)) : 0}%</td>
                      <td className="table-cell">{selectedFeedbackType == 'Module Feedback' ? 'Module Feedback' : (selectedFeedbackType == 'Content Feedback' ? 'Content Feedback' : selectedFeedbackType)}</td>
                      <td className="table-cell">{data.dism ? 'Dismissible' : 'Undismissable'}</td>
                    </tr>
                  )
                })}


              {feedbackPublishDetails && selectedFeedbackType == 'Survey Feedback' &&
                feedbackPublishDetails.map((data, i) => {
                  console.log({ data, selectedFeedbackType })
                  return (
                    <>
                      {data.act && <tr key={i}>

                        {data.p_type == 3 && data.fdbkPublishStatusLogs ? <td className="table-cell"> <Button sx={{ textTransform: 'none' }} onClick={() => { handleShowFeedbackPublisLogs(data) }}> {data.nm}</Button></td> : <td className="table-cell"> {data.nm}</td>}

                        {!data.is_old_feedback ? <td className="table-cell"> <Button sx={{ textTransform: 'none' }} onClick={() => { handleShowPreviewFeeback(data.test_id) }}> {data.test_name}</Button></td> : <td className="table-cell"> {data.test_name}</td>}

                        <td className="table-cell">{selectedCourse.name}</td>
                        <td className="table-cell">{selectedCourse.btch_name}</td>
                        <td className="table-cell">
                          {moment(data.createdAt).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}

                        </td>
                        <td className="table-cell">{data.comp_perc ? parseFloat(data.comp_perc.toFixed(2)) : 0}%</td>
                        <td className="table-cell">Survey Feedback</td>
                        <td className="table-cell">{data.dism ? 'Dismissible' : 'Undismissable'}</td>
                        <td className="table-cell">{data.fdbk_publish ? 'True' : 'False'}</td>

                        {data.p_type == 3 ? <td className="table-cell">
                          <ReschedulingFdbkForms selectedCourse={selectedCourse} data={data} /> {data.expired && 'This feedback has been expired'}
                        </td> : <td>Only recurring feedback can be scheduled</td>}
                      </tr>}
                    </>
                  )
                })}


            </tbody>
          </table>

          {FeedbackArrayList && FeedbackArrayList.length == 0 && selectedFeedbackType != 'Survey Feedback' &&
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
              <div>
                <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
              </div>
              <div></div>
              <div>
                <p style={{ paddingTop: "24px", paddingBottom: "24px" }} className='empty-text-header'>It's empty here!</p>
              </div>

            </div>
          }



          {feedbackPublishDetails && feedbackPublishDetails.length == 0 && selectedFeedbackType == 'Survey Feedback' &&
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
              <div>
                <  img style={{ width: "300px" }} src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
              </div>
              <div></div>
              <div >


                <p style={{ paddingTop: "24px", paddingBottom: "24px" }} className='empty-text-header'>It's empty here!</p>

              </div>

            </div>
          }
        </div>
        {showPreviewFeedback && <PreviewFeedback
          sectionArr={sectionArr}
          answerTemplateNameMap={answerTemplateNameMap}
          prevFeedbackTest={true}
          setShowPreviewFeedback={setShowPreviewFeedback}
        />}
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '700px',
          }}
          className='feedback-management'

          open={showFdbkLogs}
        >
          <Modal.Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div> Published Survey Feedbacks </div>
              <div onClick={(e) => {
                setShowFdbkLogs(false)
              }} style={{ cursor: "pointer" }}>×</div>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid', overflowX: "auto" }} >

              <table class="table table-bordered" style={{ marginBottom: "0rem" }}>
                <thead>
                  <tr className='tr-layout'>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "130px" }}
                    >
                      Schedule start Date
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "170px" }}
                    >
                      Schedule end Date
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "70px" }}
                    >
                      Periodicity
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "50px" }}
                    >
                      Expiry
                    </th>
                  </tr>
                </thead>

                <tbody>



                  <tr >

                    <td className="table-cell">
                      {moment(selectedFeedbackPublishDeatail.scdule_tm).utcOffset(330).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </td>
                    <td className="table-cell">
                      {moment(selectedFeedbackPublishDeatail.scdule_etim).utcOffset(330).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </td>

                    <td className="table-cell">
                      {periodicity[selectedFeedbackPublishDeatail.rpt_interval]}
                    </td>

                    <td className="table-cell">
                      {selectedFeedbackPublishDeatail.rpt_expiry} days
                    </td>
                  </tr>




                </tbody>
              </table>



            </div>

            <br></br>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid', overflowX: "auto" }} >

              <table class="table table-bordered" style={{ marginBottom: "0rem" }}>
                <thead>
                  <tr className='tr-layout'>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "130px" }}
                    >
                      Feedback No.
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "170px" }}
                    >
                      Publish Time
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "70px" }}
                    >
                      Expiry Time
                    </th>
                    <th
                      scope="col"
                      className="table-header"
                      style={{ textAlign: 'center', minWidth: "50px" }}
                    >
                      Feedback Completion % <span><Tooltip
                        title={
                          <Typography sx={{ fontSize: "12px" }}>
                            Feedback completion percentage is calculated as : <br />
                            <strong>(Number of Students Who Gave Feedback / Total Number of Students in the Batch) × 100</strong> <br />
                          </Typography>
                        }

                        arrow
                      >
                        <IconButton sx={{ m: -0.7 }}>
                          <InfoIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip></span>
                    </th>
                  </tr>
                </thead>

                <tbody>



                  {selectedFeedbackPublishDeatailog &&
                    selectedFeedbackPublishDeatailog.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td className="table-cell">
                            {data.counter}
                          </td>
                          <td className="table-cell">
                            {moment(data.publish_tim).utcOffset(330).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )}
                          </td>
                          <td className="table-cell">
                            {moment(data.expiry_tim).utcOffset(330).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )}
                          </td>

                          <td className="table-cell">
                            {data.comp_perc.toFixed(2)}%
                          </td>
                        </tr>
                      )
                    })}


                  {selectedFeedbackPublishDeatailog && selectedFeedbackPublishDeatailog.length == 0 &&
                    <tr>  <td className="table-cell" colSpan={4}>
                      No feedback has been released yet.
                    </td></tr>

                  }


                </tbody>
              </table>



            </div>

            <br></br>



          </Modal.Content>


          <ModalFooter>



            <Button
              variant="contained" style={{ background: "red" }}
              onClick={(e) => {
                setShowFdbkLogs(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>}
      {
        loading && (
          <div className="Loader">
            <Loader active inline />
          </div>
        )
      }
    </div>
  )
}

export default RescheduleFeedbackForms
