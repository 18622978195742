
import React, { useState } from 'react';
import './FeedbackDashboard.scss'
const NumericRatingScale = () => {
  const [rating, setRating] = useState(5); // Initial rating

  const handleOnChange = (event) => {
    const newRating = parseFloat(event.target.value);
    setRating(Math.min(Math.max(newRating, 1), 5)); // Clamp value between 1-10
  };

  const getColor = (value) => {
    const ratio = (value - 1) / 9; // Normalize value between 0-1
    return `hsl(${120 + ratio * 120}, 100%, ${50 + ratio * 50}%)`; // Generate color from red to green
  };

  const trackStyle = {
    width: '100%',
    height: 15, // Adjust track height as needed
    backgroundColor: 'linear-gradient(90deg, #CD2335 0.03%, #F04B3F 25.03%, #F08036 55.23%, #EFB026 76.57%, #28A745 100%)', //'#ddd'
    backgroundImage: 'url("https://cdn.pegasus.imarticus.org/feedback/BarSlider.svg")', // Replace with the actual path to your image
    // backgroundImage: 'url("https://cdn.pegasus.imarticus.org/feedback/Bar.png")', // Replace with the actual path to your image

    backgroundSize: 'cover', // Ensures the image covers the entire area
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    
  };

  const thumbStyle = {
    position: 'relative',
    width: 20,
    height: 20,
    backgroundColor: getColor(rating),
    borderRadius: '50%',
    cursor: 'pointer',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} className='feedback-management'>
        
      <input
        type="range"
        min={1}
        max={5}
        value={rating}
        step={1}
        onChange={handleOnChange}
        // style={{ ...trackStyle, appearance: 'none', width:"400px" }} // Hide default slider style
        style={{ ...trackStyle, appearance: 'none',  }} // Hide default slider style
        className="custom-slider"

      />
      {/* <div style={thumbStyle} /> */}
      <span style={{ marginLeft: 10 }}>{rating}</span>
    </div>
  );
};

export default NumericRatingScale;