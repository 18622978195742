
import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Label, Button, ModalFooter } from 'reactstrap'
import ReschedulingFdbkForms from './ReschedulingFdbkForms.jsx'
import { routes } from '../../config/configs'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { Loader, Segment } from 'semantic-ui-react'

import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import {
  startFetchCourse,
  AddFeedbackQuestion,
  startFetchBatchCourse,
  startFetchBatchCourseChapters,
  StartFetchCourseFdbk,
} from '../../actions/courseAction.js'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function ViewSurveyResponses() {
  const cookies = new Cookies()

  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [loader, setLoader] = useState(false)

  const [coursePageList, setCoursePageList] = useState([])
  const [selectedCoursePage, setSelectedCoursePage] = useState({})

  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})

  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  const [feedbackTypeList, setFeedbackTypeList] = useState([])
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const [FeedbackArrayList, setFeedbackArrayList] = useState([])
  const [columns, setColumns] = useState([])


  useEffect(() => {
    ; (async () => {
      setLoader(true)
      let res = await startFetchCourse()
      setCoursePageList([...res.data.courses])
      setLoader(false)

    })()
  }, [])

  let handleSelectCoursePage = async (crs) => {
    setLoader(true)

    console.log(crs)
    setSelectedCoursePage({ ...crs })
    let coursePage = crs
    if (coursePage._id) {
      let data = {
        crs_id: coursePage._id,
        limit: 100,
        skip: 0,
      }
      let batch_list = await startFetchBatchCourse(data)

      if (batch_list) {
        setCourseList([...batch_list.data.slicedCourses])
        setSelectedCourse({})
        setFeedbackArrayList([])
        setColumns([])

      }
      setLoader(false)

    }
  }


  let handleSelectCourse = async (batch) => {
    setLoader(true)

    console.log(batch)
    setSelectedCourse({ ...batch })
    let course = batch
    let queryURL = routes.BASE_URL_DEVELOPMENT + '/reports/dshbrd_rpts'
    var getData = {
      url: queryURL,
      method: 'POST',
      data: {
        filterCourse: selectedCoursePage.name,
        filterBatch: course.btch_name,
        repKey: "surveyFeedback",
      },
      headers: {
        'x-access-token': cookies.get('at'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    axios(getData)
      .then((res) => {
        console.log(res.data.data)
        // if (!res.data.success) {
        //   alert(res.data.message)
        //   setLoader(false)
        //   return
        // }
        // if (res.data.data.dataForTable.length == 0) {
        //   alert('No Data found.')
        //   setLoader(false)
        //   return
        // }
        // setData(res.data.data)
        // setHeaders(res.data.data.columns)
        // setTotalPages(Math.floor(res.data.data.totalDataInQuery / tableSize))
        // setTotalDataInQuery(res.data.data.totalDataInQuery)

        // console.log(
        //   ' this is what we want to see ::: :',
        //   filterName !== 'Location' && filterName !== 'Batch'
        // )
        // if (
        //   res.data.data.locationFilterDropdown &&
        //   res.data.data.locationFilterDropdown.length > 0 &&
        //   filterName !== 'Location' &&
        //   filterName !== 'Batch' &&           filterName !== 'FeedbackName'

        // ) {
        //   console.log(
        //     ' ============ \n\n\n\n LocationDropdown : ',
        //     res.data.data.locationFilterDropdown
        //   )
        //   setLocationDropdown(res.data.data.locationFilterDropdown)
        // }
        // if (
        //   res.data.data.batchFilterDropdown &&
        //   res.data.data.batchFilterDropdown.length > 0 &&     filterName !== 'Batch' &&           filterName !== 'FeedbackName'
        // ) {
        //   console.log(
        //     ' ============ \n\n\n\n BatchDropdown : ',
        //     res.data.data.batchFilterDropdown
        //   )
        //   setBatchDropdown(res.data.data.batchFilterDropdown)
        // }
        // if (
        //   res.data.data.feedbackNameFilterDropdown &&
        //   res.data.data.feedbackNameFilterDropdown.length > 0 &&
        //   filterName !== 'FeedbackName'
        // ) {
        //   setFeedbackNameDropdown(res.data.data.feedbackNameFilterDropdown)
        // }
        if (res.data.data.columns) {
          setColumns([...res.data.data.columns[0].columns])
        }else{
          setColumns([])
        }
        setFeedbackArrayList([...res.data.data.dataForTable])

        setLoader(false)
        console.log(' ================ \n\n')
      })
      .catch((err) => {
        console.error(err)
        // alert("This was not supposed to happen! Please try again later.")
        setLoader(false)
      })


  }

  return (
    <div style={{ backgroundColor: 'rgba(37, 92, 168, 0.05)', height: '100vh', overflowX: "hidden" }} className='feedback-management'>
      {!loader && <div>
        <FeedbackDashboard />
        <div className="side-bar-tab-view">
          {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Select Program</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            value={selectedCoursePage.name}
            onChange={handleSelectCoursePage}
            input={
              <OutlinedInput id="select-multiple-chip" label="Select Program" />
            }
            renderValue={(selected) => {
              return selected.name
            }}
            MenuProps={MenuProps}
          >
            {coursePageList.map((coursePage) => (
              <MenuItem
                key={coursePage._id}
                value={coursePage}
                // style={getStyles(name, personName, theme)}
              >
                {coursePage.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCoursePage && selectedCoursePage._id && (
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={selectedCourse}
              onChange={handleSelectCourse}
              input={
                <OutlinedInput id="select-multiple-chip" label="Select Batch" />
              }
              renderValue={(selected) => {
                return selected.btch_name
              }}
              MenuProps={MenuProps}
            >
              {courseList.map((course) => (
                <MenuItem key={course._id} value={course}>
                  {course.btch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "24px" }}>

            <div style={{ width: "300px" }}>
              <Autocomplete
                style={{ width: "300px" }}
                className="select-course"
                disableClearable
                value={selectedCoursePage.name ? selectedCoursePage.name : 'Select a Course'}
                onChange={(e, crs) => {
                  handleSelectCoursePage(crs)
                }}

                getOptionLabel={(course) => {
                  return course.name || course
                }}
                getOptionSelected={(option, test) => option.name == test}
                options={coursePageList}
                disabled={coursePageList.length === 0 ? true : false}
                renderInput={(params) => {
                  return (
                    <TextField
                      label={coursePageList.length == 0 ? 'Loading...' : ''}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />
            </div>
            <div style={{ width: "300px" }}>

              {selectedCoursePage && selectedCoursePage._id && (
                // <FormControl sx={{ m: 1, width: 200 }}>
                //   <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
                //   <Select
                //     labelId="demo-multiple-chip-label"
                //     id="demo-multiple-chip"
                //     value={selectedCourse}
                //     onChange={handleSelectCourse}
                //     input={
                //       <OutlinedInput id="select-multiple-chip" label="Select Batch" />
                //     }
                //     renderValue={(selected) => {
                //       return selected.btch_name
                //     }}
                //     MenuProps={MenuProps}
                //   >
                //     {courseList.map((course) => (
                //       <MenuItem key={course._id} value={course}>
                //         {course.btch_name}
                //       </MenuItem>
                //     ))}
                //   </Select>
                // </FormControl>

                <Autocomplete
                  style={{ width: "300px" }}
                  className="select-course"
                  disableClearable
                  value={selectedCourse.btch_name ? selectedCourse.btch_name : 'Select a Batch'}
                  onChange={(e, batch) => {
                    handleSelectCourse(batch)
                  }}

                  getOptionLabel={(batch) => {
                    return batch.btch_name || batch
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={courseList}
                  disabled={courseList.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={courseList.length == 0 ? 'Loading...' : ''}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              )}
            </div>
          </div>
          <hr></hr>
          <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid', overflowX: "auto" }} >
            <table class="table table-bordered" style={{ marginBottom: "0rem" }}>
              <thead>
                <tr className='tr-layout'>
                  {columns.map((col) => {
                    return (
                      <th
                        scope="col"
                        className="table-header"
                        style={{ textAlign: 'center', minWidth: "130px" }}
                      >
                        {col.Header == 'survey_fdbk_name' ? 'Survey Feedback Name' : col.Header}
                      </th>
                      
                    )
                  })}

                </tr>
              </thead>

              <tbody>
                {FeedbackArrayList &&
                  FeedbackArrayList.map((data) => {
                    return (
                      <tr  >
                        {Object.keys(data).map((dt, index) => {
                          return (
                            <td className="table-cell">{data[columns[index].Header]}</td>

                          )
                        })}
                      </tr>
                    )
                  })}
                {FeedbackArrayList && FeedbackArrayList.length == 0 &&
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                    <div>
                      <  img style={{ width: "300px" }} src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                    </div>
                    <div></div>
                    <div >


                      <p style={{ paddingTop: "24px", paddingBottom: "24px" }} className='empty-text-header'>It's empty here!</p>

                    </div>

                  </div>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>}
      {
        loader && (
          <div className="Loader">
            <Loader active inline />
          </div>
        )
      }
    </div>
  )
}

export default ViewSurveyResponses

