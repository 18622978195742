import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import AddFeedbackForm from './AddFeedbackForm.jsx'
import EditFeedbackForm from './EditFeedbackForm.jsx'
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  DeleteFeebackInCoursePage,
  fetchFeedbackSection,
  startFetchFeedbackScale,
  AddFeedbackQuestion,
  AddSubjectiveFeedbackQuestion,
  DelFeedbackSection,
  DelFeedbackQuestion,
  EditFeedbackQuestion,
  startFetchDynamicField
} from '../../actions/courseAction.js'
import { Label, Button, ButtonGroup } from 'reactstrap'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import moment from 'moment'
import { Loader, Segment } from 'semantic-ui-react'
import FeedbackClone from './FeedbackClone.jsx'
import AddSection from './AddSection.jsx'
import { StartRatingScale } from './StartRatingScale.jsx'
import NumericRatingScale from './NumericRatingScale.jsx'
import EmojiScaleRating from './EmojiScaleRating.jsx'

import NumericScale from './NumericScale.jsx'
import QualityScale from './QualityScale.jsx'
import { BorderBottom } from '@mui/icons-material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PreviewFeedback from './PreviewFeedback.jsx'
function CreateFeedback() {
  const [coursePage, setCoursePage] = useState([])
  const [questionType, setQuestionType] = useState(4)

  const [coursePageObj, setCoursePageObj] = useState({})

  const [coursePageName, setCoursePageName] = useState()
  const [feedbackName, setFeedbackName] = useState('')

  const [testList, setTestList] = useState([])

  const [selectedSectionObj, setSelectedSectionObj] = useState({})

  const [scaleName, setScaleName] = useState('')
  const [selectedScaleObj, setSelectedScaleObj] = useState({})
  const [selectedFeedbackObj, setSelectedFeedbackObj] = useState({})
  const [sectionArr, setSectionArr] = useState([])
  const [feedbackScaleId, setFeedbackScaleId] = useState()
  const [selectedFeedbackScale, setSelectedFeedbackScale] = useState({})

  const [sectionId, setSectionId] = useState()
  const [sectionName, setSectionName] = useState('')
  const [answerTemplateNameMap, setAnswerTemplateNameMap] = useState({})
  const [questionText, setQuestionText] = useState('')
  const [isCompulsory, setIsCompulsory] = useState(false)
  const [editQues, setEditQues] = useState(false)
  const [questionId, setQuestionId] = useState()
  const [dynamicFieldList, setDynamicFieldList] = useState([])
  const [sectionIdMap, setSectionIdMap] = useState({})
  const [totalQuestion, setTotalQuestion] = useState(0)



  const [modalOpen, setModalOpen] = useState(false)

  const [loading, setLoading] = useState(false)
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  const [feedbackScale, setFeedbackScale] = useState([])
  useEffect(() => {
    ; (async () => {
      setLoading(true)
      await getCourse()
      await getDynamicField()
      let currentUrl = window.location.href
      let urlParams = new URLSearchParams(currentUrl.split('?')[1])
      let crs_pg_id = urlParams.get('crs_pg_id')
      let res = await startFetchFeedbackScale()
      if (res.data && res.data.feedback_scale) {
        setFeedbackScale([...res.data.feedback_scale])
        setAnswerTemplateNameMap({ ...res.data.answer_template_map })
      }

      if (crs_pg_id) {
        let data = await startFetchCourse()
        console.log(data.data.courses)
        if (data.data && data.data.courses) {
          setCoursePage([...data.data.courses])
        }
        console.log(crs_pg_id)

        let course_page_obj = data.data.courses.filter((crs_pg) => crs_pg._id == crs_pg_id)
        console.log({ course_page_obj })

        if (course_page_obj[0]) {
          setCoursePageObj({ ...course_page_obj[0] })
          setCoursePageName(course_page_obj[0].name)
          let res = await startFetchAddedFeebackInCoursePage(crs_pg_id)
          setSelectedFeedbackObj({})

          console.log({ res })
          if (res.data.tests) {
            let feedbacklist = res.data.tests.filter((test) => !test.is_old_feedback)
            setTestList([...feedbacklist])
            setFeedbackName()
          }
        }
      }
      setLoading(false)
    })()
  }, [])






  let getDynamicField = async () => {
    setLoading(true)
    let data = await startFetchDynamicField()
    console.log(data.data)
    if (data && data.data) {
      setDynamicFieldList([...data.data])
    }
    setLoading(false)
  }


  let getCourse = async () => {
    setLoading(true)
    let data = await startFetchCourse()
    console.log(data.data.courses)
    if (data.data && data.data.courses) {
      setCoursePage([...data.data.courses])
    }
    setLoading(false)
  }
  let handleSave = async () => {
    let res
    let question_text = ''
    if (questionText) {
      question_text = questionText.trim()
    }

    setQuestionText(question_text)
    setLoading(true)

    if (editQues) {
      if (!coursePageObj._id || !selectedFeedbackObj._id || !sectionId || (questionType == 4 && !selectedFeedbackScale._id) || !questionType || !question_text || question_text == '' || !questionId || question_text == '.') {
        alert("Invalid Details")
        setLoading(false)
        return
      }
      console.log({ coursePageObj, selectedFeedbackScale, selectedFeedbackObj })
      if (questionType == 4) {
        res = await EditFeedbackQuestion(
          coursePageObj._id,
          selectedFeedbackObj._id,
          sectionId,
          selectedFeedbackScale._id,
          questionType,
          question_text,
          isCompulsory,
          questionId,
        )
      } else {
        res = await EditFeedbackQuestion(
          coursePageObj._id,
          selectedFeedbackObj._id,
          sectionId,
          undefined,
          questionType,
          question_text,
          isCompulsory,
          questionId,
        )
      }
      setEditQues(false)
    } else {
      if (!coursePageObj._id || !sectionId || (questionType == 4 && !selectedFeedbackScale._id) || !questionType || !questionText || questionText == '') {
        alert("Invalid Details")
        setLoading(false)
        return
      }
      if (questionType == 4) {
        res = await AddFeedbackQuestion(
          coursePageObj._id,
          selectedFeedbackObj._id,
          sectionId,
          selectedFeedbackScale._id,
          questionType,
          question_text,
          isCompulsory
        )
      } else {
        res = await AddSubjectiveFeedbackQuestion(
          coursePageObj._id,
          selectedFeedbackObj._id,
          sectionId,
          questionType,
          question_text,
          isCompulsory
        )
      }
    }
    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      setQuestionText('')
      setIsCompulsory(false)
      setSectionName('')
      setSectionId()
      setSelectedFeedbackScale()
      setFeedbackScaleId()
      setScaleName('')
      setLoading(false)
      setModalOpen(true)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }

    await handleSelectFeedback(selectedFeedbackObj._id)
    setLoading(false)
  }

  let handleSaveSec = async () => {
    setLoading(true)

    let res = await fetchFeedbackSection(coursePageObj._id, selectedFeedbackObj._id)
    if (res && res.data && res.data.find_section) {
      setSectionArr([...res.data.find_section])
      let section_id_map = {}
      setTotalQuestion(res.data.total_question)

      for (let i = 0; i < res.data.find_section.length; i++) {
        section_id_map[res.data.find_section[i]._id] = false
        setSectionIdMap({ ...section_id_map })
      }
    }
    setLoading(false)

  }
  let courseChanged = async (crs_pg_id) => {
    console.log(crs_pg_id)
    setLoading(true)

    let course_page_obj = coursePage.filter((crs_pg) => crs_pg._id == crs_pg_id)
    console.log({ course_page_obj })

    if (course_page_obj[0]) {
      setCoursePageObj({ ...course_page_obj[0] })
      setCoursePageName(course_page_obj[0].name)
      let res = await startFetchAddedFeebackInCoursePage(crs_pg_id)
      setSelectedFeedbackObj({})

      console.log({ res })
      if (res.data.tests) {
        let feedbacklist = res.data.tests.filter((test) => !test.is_old_feedback)
        setTestList([...feedbacklist])
        setFeedbackName()
      }
    }
    setLoading(false)
  }

  let handleSelectFeedback = async (tid) => {
    setLoading(true)

    let test_obj = testList.filter((test) => test._id == tid)
    console.log({ test_obj })

    if (test_obj[0]) {
      setFeedbackName(test_obj[0].name)
      setSelectedFeedbackObj({ ...test_obj[0] })
    }
    let res = await fetchFeedbackSection(coursePageObj._id, test_obj[0]._id)
    if (res && res.data && res.data.find_section) {
      setSectionArr([...res.data.find_section])
      let section_id_map = {}
      setTotalQuestion(res.data.total_question)

      for (let i = 0; i < res.data.find_section.length; i++) {
        section_id_map[res.data.find_section[i]._id] = false
        setSectionIdMap({ ...section_id_map })
      }
    }
    setLoading(false)
  }

  let handleFetchFeedbackForm = async () => {
    setLoading(true)

    let res = await startFetchAddedFeebackInCoursePage(coursePageObj._id)

    console.log({ res })
    if (res.data.tests) {
      let feedbacklist = res.data.tests.filter((test) => !test.is_old_feedback)
      setTestList([...feedbacklist])
      setFeedbackName()
    }

    setLoading(false)
  }

  let handleQuestionType = () => {
    if (questionType == 4) {
      setQuestionType(3)
    } else {
      setQuestionType(4)
    }
  }

  const handleEditQues = (event, ques) => {
    console.log({ ques }, ques.text)
    setQuestionId(ques._id)
    setIsCompulsory(ques.compulsory)
    setQuestionText(ques.text)
    let temp = sectionArr.filter((temp) => temp._id == ques.sectionId)

    console.log({ temp })
    if (temp[0]) {
      setSectionName(temp[0].name)
    }
    setSectionId(ques.sectionId)

    if (ques.answer_set_id) {
      temp = feedbackScale.filter((temp) => temp._id == ques.answer_set_id)

      if (temp[0]) {
        setScaleName(temp[0].ans_set_name)
        setSelectedFeedbackScale({ ...temp[0] })
        console.log(temp[0])
      }

      setFeedbackScaleId(ques.answer_set_id)
    }
    setQuestionType(ques.type)
    setEditQues(true)
  }

  let scaleChanged = (val) => {
    console.log({ val })
    let temp = feedbackScale.filter((temp) => temp._id == val)

    console.log({ temp })
    if (temp[0]) {
      setScaleName(temp[0].ans_set_name)
      setSelectedFeedbackScale({ ...temp[0] })
      console.log(temp[0])
    }

    setFeedbackScaleId(val)
  }

  let handleSectionArrow = (id) => {
    let section_id_map = sectionIdMap
    console.log(id, section_id_map, section_id_map[id])
    section_id_map[id] = !section_id_map[id]
    setSectionIdMap({ ...section_id_map })
  }
  let sectionChanged = (val) => {
    console.log({ val })
    let temp = sectionArr.filter((temp) => temp._id == val)

    console.log({ temp })
    if (temp[0]) {
      setSectionName(temp[0].ans_set_name)
    }

    setSectionId(val)
  }

  let handleDeleteSection = async (e, section) => {
    setLoading(true)
    let confirm = window.confirm(
      'Are you sure you want delete section ' + section.name + '?'
    )
    if (!confirm) {
      handleSectionArrow(section._id)
      setLoading(false)
      return
    }

    let delfdbksec = await DelFeedbackSection(coursePageObj._id, selectedFeedbackObj._id, section._id)
    let res = await fetchFeedbackSection(coursePageObj._id, selectedFeedbackObj._id)
    if (res && res.data && res.data.find_section) {
      setSectionArr([...res.data.find_section])
      let section_id_map = {}
      setTotalQuestion(res.data.total_question)


      for (let i = 0; i < res.data.find_section.length; i++) {
        section_id_map[res.data.find_section[i]._id] = false
        setSectionIdMap({ ...section_id_map })
      }
    }

    if ('statusCode' in delfdbksec && delfdbksec.statusCode == 403) {
      window.alert(delfdbksec.error)
      setLoading(false)
      return
    }
    if (delfdbksec.success) {
      window.alert('Deleted Successfully')
      setLoading(false)
    }
    if (!delfdbksec.success) {
      if (delfdbksec.message) {
        window.alert(delfdbksec.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }
    setLoading(false)

  }

  let handleDeleteQues = async (e, ques) => {
    setLoading(true)

    let confirm = window.confirm(
      'Are you sure you want delete question ' + ques.text + '?'
    )
    if (!confirm) {
      setLoading(false)
      return
    }

    let delfdbkques = await DelFeedbackQuestion(coursePageObj._id, selectedFeedbackObj._id, ques.sectionId, ques._id)
    let res = await fetchFeedbackSection(coursePageObj._id, selectedFeedbackObj._id)
    if (res && res.data && res.data.find_section) {
      setSectionArr([...res.data.find_section])
      let section_id_map = {}
      setTotalQuestion(res.data.total_question)


      for (let i = 0; i < res.data.find_section.length; i++) {
        section_id_map[res.data.find_section[i]._id] = false
        setSectionIdMap({ ...section_id_map })
      }
    }

    if ('statusCode' in delfdbkques && delfdbkques.statusCode == 403) {
      window.alert(delfdbkques.error)
      setLoading(false)
      return
    }
    if (delfdbkques.success) {
      window.alert('Deleted Successfully')
      setLoading(false)
    }
    if (!delfdbkques.success) {
      if (delfdbkques.message) {
        window.alert(delfdbkques.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }
    setLoading(false)
  }

  return (
    <div style={{ backgroundColor: 'rgba(37, 92, 168, 0.05)', height: '100vh', overflowX: "hidden" }} className='feedback-management'>
      {!loading && (
        <div  >
          <FeedbackDashboard />
          <div className="side-bar-tab-view" >
            <div className="select-course-create-feeedback">
              <div className="form-group">
                {/* <select
                  className="select-course"
                  id="overrideSelectCourse"
                  onChange={(e) => courseChanged(e.target.value)}
                >
                  <option className="dropdown-item " hidden>
                    {coursePageName ? coursePageName : ' Select a Course name'}
                  </option>
                  {coursePage.map((crs, key) => (
                    <option
                      key={key}
                      className="dropdown-item "
                      value={crs._id}
                    >
                      {crs.name}
                    </option>
                  ))}
                </select> */}

                <Autocomplete
                  className="select-course"
                  disableClearable
                  value={coursePageName ? coursePageName : 'Select a Course'}
                  onChange={(e, crs) => {
                    courseChanged(crs._id)
                  }}
                  getOptionLabel={(course) => {
                    return course.name || course
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={coursePage}
                  disabled={coursePage.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={coursePage.length == 0 ? 'Loading...' : 'Select a Course'}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div style={{ paddingTop: "16px" }}>
              {coursePageObj._id && (
                <div >
                  <div className="select-feedback-form">
                    <div style={{ width: '100%' }}>
                      <div className="selected-fdbk-dropdown">

                        <Autocomplete
                          className="select-course"
                          disableClearable
                          value={feedbackName ? feedbackName : 'Select Feedback Name'}
                          onChange={(e, test) => {
                            handleSelectFeedback(test._id)
                          }}
                          getOptionLabel={(test) => {
                            return test.name || test
                          }}
                          getOptionSelected={(option, test) => option.name == test}
                          options={testList}
                          disabled={testList.length === 0 ? true : false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                label={testList.length == 0 ? 'Loading...' : 'Select Feedback Name'}
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                        <div className="select-feedback-form">
                          <AddFeedbackForm coursePageObj={coursePageObj} handleFetchFeedbackForm={handleFetchFeedbackForm} />
                        </div>
                        {feedbackName && feedbackName != '' && selectedFeedbackObj && selectedFeedbackObj._id && <div className="select-feedback-form">
                          <EditFeedbackForm feedbackName={feedbackName} selectedFeedbackObj={selectedFeedbackObj} handleFetchFeedbackForm={handleFetchFeedbackForm} />
                        </div>
                        }


                      </div>
                    </div>
                  </div>
                  {feedbackName ? (
                    <div>
                      <div className="select-course-create-feeedback">
                        <p className="table-header-text">
                          Add/Remove Questions
                        </p>
                        {/* <Button color="success"> + Add Section</Button> */}
                        <AddSection data={selectedFeedbackObj} section={{}} edit={false} dynamicFieldList={dynamicFieldList} handleSaveSec={handleSaveSec}  agamEnabled = {coursePageObj.if_schdl_enbld}/>
                      </div>
                      <hr style={{ border: "0.5px solid rgba(60, 72, 82, 0.3)" }}></hr>
                      <div className="add-preview-sec" >
                        <div
                          style={{
                            borderRight: '0.5px solid black',
                            width: '50%',
                          }}

                        >
                          <div className="preview">
                            <div
                              className="preview-text"
                              style={{ marginLeft: '17px' }}
                            >
                              Preview
                            </div>
                            <div
                              className="preview-text"
                              style={{ marginRight: '20px' }}
                            >
                              Total : {totalQuestion}
                            </div>
                          </div>
                          <div>
                            {sectionArr && sectionArr.length > 0 &&
                              sectionArr.map((section, index) => {
                                return (
                                  <div key={index}>
                                    <div className="preview section-hover" style={{ height: "64px", cursor: "pointer" }} onClick={() => {
                                      handleSectionArrow(section._id)
                                    }}>

                                      <div
                                        className="section-text"
                                        style={{ marginLeft: '16px', display: "flex", justifyContent: "space-evenly" }}
                                      >
                                        {/* <div style={{ marginLeft: '16px' }}
                                        >
                                          <img src="https://cdn.pegasus.imarticus.org/feedback/icon-park-outline_drag.svg"></img>

                                        </div> */}
                                        <div style={{ marginLeft: '1px' }}>{section.name ? section.name : '-'}
                                        </div>
                                      </div>
                                      <div style={{ marginRight: '4px' }}>
                                        <div className="select-feedback-form" >
                                          <AddSection data={selectedFeedbackObj} section={section} edit={true} handleSaveSec={handleSaveSec} agamEnabled = {coursePageObj.if_schdl_enbld}/>
                                          <Button
                                            style={{ margin: '5px', width: "47px", height: "36.5px" }}
                                            outline
                                            onClick={(e) =>
                                              handleDeleteSection(e, section)
                                            }
                                          >
                                            <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_delete-outline.svg"></img>
                                          </Button>
                                          <div style={{ margin: '8px' }}>
                                            <IconButton aria-label="delete"
                                            // onClick={() => {
                                            //   handleSectionArrow(section._id)
                                            // }}
                                            >
                                              {sectionIdMap[section._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}     </IconButton>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    {sectionIdMap[section._id] && <div >
                                      <>
                                        {section.ques && section.ques.length > 0 &&
                                          section.ques.map((ques, index) => {
                                            return (
                                              <div
                                                // className="question-preview"
                                                key={index}
                                                style={{
                                                  borderBottom:
                                                    '1px solid #c1c1c1',
                                                  margin: '17px',
                                                }}
                                              >
                                                <div
                                                  className="add-ques-sub-header"
                                                  style={{ marginLeft: '17px' }}
                                                >
                                                  {index + 1 + ". "}

                                                  {ques.text ? ques.text : '-'}
                                                  {ques.compulsory && <span style={{ color: 'red', fontSize: '18px' }}>&nbsp;*</span>}

                                                </div>

                                                <div style={{ margin: '27px' }}>
                                                  {ques.feedback_scale &&
                                                    ques.feedback_scale
                                                      .ansoptions &&
                                                    answerTemplateNameMap &&
                                                    answerTemplateNameMap[
                                                    ques.feedback_scale
                                                      .template_id
                                                    ] ==
                                                    'Numeric rating Scale' && (
                                                      <NumericRatingScale
                                                        answerSetOptionList={
                                                          ques.feedback_scale
                                                            .ansoptions
                                                        }
                                                      />
                                                    )}
                                                  {ques.feedback_scale &&
                                                    ques.feedback_scale
                                                      .ansoptions &&
                                                    answerTemplateNameMap &&
                                                    answerTemplateNameMap[
                                                    ques.feedback_scale
                                                      .template_id
                                                    ] == 'Numeric Scale' && (
                                                      <NumericScale
                                                        answerSetOptionList={
                                                          ques.feedback_scale
                                                            .ansoptions
                                                        }
                                                      />
                                                    )}
                                                  {ques.feedback_scale &&
                                                    ques.feedback_scale
                                                      .ansoptions &&
                                                    answerTemplateNameMap &&
                                                    answerTemplateNameMap[
                                                    ques.feedback_scale
                                                      .template_id
                                                    ] == 'Star rating Scale' && (
                                                      <StartRatingScale
                                                        answerSetOptionList={
                                                          ques.feedback_scale
                                                            .ansoptions
                                                        }
                                                        index={index}
                                                      />
                                                    )}
                                                  {ques.feedback_scale &&
                                                    ques.feedback_scale
                                                      .ansoptions &&
                                                    answerTemplateNameMap &&
                                                    answerTemplateNameMap[
                                                    ques.feedback_scale
                                                      .template_id
                                                    ] ==
                                                    'Emoji  rating Scale' && (
                                                      <EmojiScaleRating
                                                        answerSetOptionList={
                                                          ques.feedback_scale
                                                            .ansoptions
                                                        }
                                                        index={index}

                                                      />
                                                    )}
                                                  {ques.feedback_scale &&
                                                    ques.feedback_scale
                                                      .ansoptions &&
                                                    answerTemplateNameMap &&
                                                    answerTemplateNameMap[
                                                    ques.feedback_scale
                                                      .template_id
                                                    ] == 'Quality Scale' && (
                                                      <QualityScale
                                                        answerSetOptionList={
                                                          ques.feedback_scale
                                                            .ansoptions
                                                        }
                                                      />
                                                    )}
                                                  {ques.type &&
                                                    ques.type == 3
                                                    &&
                                                    (
                                                      <TextField
                                                        id="outlined-multiline-static"
                                                        label="Type here"
                                                        multiline
                                                        rows={4}
                                                        style={{ width: '100%' }}
                                                      />
                                                    )}
                                                  {ques.type && ques.type == 6 && (
                                                    <div>

                                                      <FormControl fullWidth>
                                                        <InputLabel >Please select an option</InputLabel>
                                                        <Select defaultValue="">
                                                          <MenuItem value={10}>Faculty 1</MenuItem>
                                                          <MenuItem value={20}>Faculty 2</MenuItem>
                                                          <MenuItem value={30}>Faculty 3</MenuItem>
                                                          <MenuItem value={40}>Faculty 4</MenuItem>
                                                        </Select>
                                                      </FormControl>
                                                    </div>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                  }}
                                                >
                                                  {!(ques.type == 5 || ques.type == 6) && <div className="edit-del-ques">
                                                    <Button
                                                      style={{ margin: '5px' }}
                                                      outline
                                                      onClick={(e) =>
                                                        handleEditQues(e, ques)
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_edit-outline.svg"></img>
                                                    </Button>
                                                    <Button
                                                      style={{ margin: '5px', width: "47px", height: "36.5px" }}

                                                      outline
                                                      onClick={(e) =>
                                                        handleDeleteQues(e, ques)
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_delete-outline.svg"></img>

                                                    </Button>
                                                  </div>}
                                                </div>
                                              </div>
                                            )
                                          })}
                                        {((!section.is_dynamic && section.ques && section.ques.length == 0) || (section.is_dynamic && section.ques && section.ques.length == 1)) &&
                                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", gap: "8px" }}>
                                            <div>
                                              <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                                            </div>
                                            <div>
                                              <p className='empty-text-header'>Its empty here!</p>
                                            </div>
                                            <div style={{ width: "333px" }}>
                                              <p className='empty-text-desc'>Add questions to view/edit them</p>
                                            </div>
                                          </div>}
                                      </>
                                      <br></br>
                                      <br></br>
                                      <br></br>
                                    </div>}

                                  </div>
                                )
                              })}

                            {sectionArr && sectionArr.length == 0 &&
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", gap: "8px" }}>
                                <div>
                                  <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                                </div>
                                <div>
                                  <p className='empty-text-header'>Its empty here!</p>
                                </div>
                                <div style={{ width: "333px" }}>
                                  <p className='empty-text-desc'>Add Sections to view/edit them</p>
                                </div>
                              </div>
                            }

                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              marginRight: '39px',
                              marginTop: '10px',
                              paddingBottom: '147px',
                            }}
                          >
                            {/* <Button className="save-btn" onClick={handleSave}>
                              <span className="btn-text">Create Form</span>
                            </Button> */}
                            <PreviewFeedback
                              sectionArr={sectionArr}
                              answerTemplateNameMap={answerTemplateNameMap}
                            />
                          </div>
                        </div>
                        <div
                          style={{ width: '50%', }}
                        >
                          <div className="preview">
                            <div>
                              {' '}
                              <div
                                className="preview-text"
                                style={{ marginLeft: '17px' }}
                              >
                                QUESTION
                              </div>
                              <div
                                className="preview-text"
                                style={{ marginRight: '10px' }}
                              ></div>
                            </div>
                          </div>
                          <div className="add-edit-ques" >
                            {' '}
                            <div className="add-ques-sub-header">
                              <p>Question Type</p>
                            </div>
                            <div>
                              <ButtonGroup style={{ width: '100%' }}>
                                <Button
                                  onClick={handleQuestionType}
                                  color={
                                    questionType == 4 ? 'success' : 'secondary'
                                  }
                                  style={{ width: '40%' }}
                                >
                                  Objective
                                </Button>
                                <Button
                                  onClick={handleQuestionType}
                                  style={{ width: '40%' }}
                                  color={
                                    questionType == 3 ? 'success' : 'secondary'
                                  }
                                >
                                  Subjective
                                </Button>
                              </ButtonGroup>
                            </div>
                            <div className="add-ques-sub-header">
                              <p>Question</p>
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1, width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  id="standard-basic"
                                  label="Type your question here"
                                  variant="standard"
                                  value={questionText}
                                  onChange={(e) => {
                                    setQuestionText(e.target.value)
                                  }}
                                />
                              </Box>
                            </div>
                            <div className="add-ques-sub-header">
                              <p>Choose Section</p>
                              <select
                                className="select-scale-section"
                                id="overrideSelectCourse"
                                onChange={(e) => sectionChanged(e.target.value)}
                                style={{
                                  padding: "8px"
                                }}
                              >
                                <option className="dropdown-item " hidden>
                                  {sectionName ? sectionName : 'Select Section'}
                                </option>
                                {sectionArr.map((section, key) => (
                                  <option
                                    key={key}
                                    className="dropdown-item "
                                    value={section._id}
                                  >
                                    {section.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              {questionType == 4 && (
                                <div className="add-ques-sub-header" >
                                  <p>Choose scale type</p>
                                  <select
                                    className="select-scale-section"
                                    id="overrideSelectCourse"
                                    onChange={(e) => scaleChanged(e.target.value)}
                                    style={{
                                      padding: "8px"
                                    }}
                                  >
                                    <option className="dropdown-item " hidden>
                                      {scaleName ? scaleName : 'Select Scale'}
                                    </option>
                                    {feedbackScale && feedbackScale.map((scale, key) => (
                                      <option
                                        key={key}
                                        className="dropdown-item "
                                        value={scale._id}
                                      >
                                        {scale.ans_set_name}
                                      </option>
                                    ))}
                                  </select>
                                  <div>
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions && <>  <br></br>
                                      <br></br></>}
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      selectedFeedbackScale.template_id
                                      ] == 'Numeric rating Scale' && (
                                        <NumericRatingScale
                                          answerSetOptionList={
                                            selectedFeedbackScale.ansoptions
                                          }
                                        />
                                      )}
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      selectedFeedbackScale.template_id
                                      ] == 'Numeric Scale' && (
                                        <NumericScale
                                          answerSetOptionList={
                                            selectedFeedbackScale.ansoptions
                                          }
                                        />
                                      )}
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      selectedFeedbackScale.template_id
                                      ] == 'Star rating Scale' && (
                                        <StartRatingScale
                                          answerSetOptionList={
                                            selectedFeedbackScale.ansoptions
                                          }
                                        />
                                      )}
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      selectedFeedbackScale.template_id
                                      ] == 'Emoji  rating Scale' && (
                                        <EmojiScaleRating
                                          answerSetOptionList={
                                            selectedFeedbackScale.ansoptions
                                          }
                                        />
                                      )}
                                    {selectedFeedbackScale && selectedFeedbackScale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      selectedFeedbackScale.template_id
                                      ] == 'Quality Scale' && (
                                        <QualityScale
                                          answerSetOptionList={
                                            selectedFeedbackScale.ansoptions
                                          }
                                        />
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div>
                              <div style={{ display: "flex", alignItems: "center", width: "40%", justifyContent: "space-between" }} className="add-ques-sub-header">
                                {/* <div className="add-ques-sub-header" style={{textAlign:"left"}}>
                                <span>Compulsory</span> 
                                </div> */}
                                <div><span>Compulsory</span></div>

                                <div>
                                  {' '}
                                  <Checkbox
                                    onChange={() =>
                                      setIsCompulsory(!isCompulsory)
                                    }
                                    checked={isCompulsory}
                                  />
                                  <span>Yes</span>
                                </div>
                                {/* <div><span>Yes</span></div> */}
                              </div>
                              <div>
                                {' '}
                                Note: Not able to find your desired Answer
                                set? Create your own by <Link to="/feedback-management/manage-scales/" >
                                  {' '}
                                  clicking here
                                </Link>
                              </div>



                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row-reverse',
                                  marginTop: '10px',
                                  paddingBottom: '48px',
                                }}
                              >
                                <Button
                                  className="save-btn"
                                  onClick={handleSave}
                                >
                                  <span className="btn-text">Save</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : <div>
                    <div className="select-course-create-feeedback">
                      <p className="table-header-text">
                        Add/Remove Questions
                      </p>
                      <hr style={{ border: "0.5px solid rgba(60, 72, 82, 0.3)" }}></hr>

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", gap: "8px" }}>
                      <div>
                        <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                      </div>
                      <div>
                        <p className='empty-text-header'>Its empty here!</p>
                      </div>
                      <div style={{ width: "333px" }}>
                        <p className='empty-text-desc'>Select a Feedback Form</p>
                      </div>
                    </div></div>}
                </div>
              )}
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      )}

      <Modal style={{
        height: 'fit-content',
        margin: 'auto',
        marginTop: 'auto',
        width: '328px',
        height: "224px"
      }} isOpen={modalOpen} centered={true} >
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <div><img style={{ height: "72px", width: "72px" }} src="https://cdn.pegasus.imarticus.org/feedback/done_teach.svg"></img></div>
            <div className='done-text' style={{ textAlign: "center" }}>Success! The Feedback Question is now created</div>
            <Button color='success' onClick={(e) => {

              setModalOpen(false)
            }}> Done</Button>

          </div>
        </ModalBody>

      </Modal>
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}



export default CreateFeedback
