import React, { useState, useEffect } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Label, Button, ModalFooter } from 'reactstrap'
import { history } from '../../index.js'
import { Loader, Segment } from 'semantic-ui-react'

import AddAndEditFeedbackScale from './AddAndEditFeedbackScale.jsx'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import { startFetchFeedbackScale } from '../../actions/courseAction.js'

function ManageScale() {
  const [feedbackScale, setFeedbackScale] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [answerTemplateList, setAnswerTemplateList] = useState([])
  const [editFeedbackScale, setEditFeedbackScale] = useState({})
  const [editFdbkScale, setEditFdbkScale] = useState(false)
  const [isDefault, setIsDefault] = useState(false)

  useEffect(() => {
    ; (async () => {
      setLoading(true)
      let res = await startFetchFeedbackScale()
      if (res.data && res.data.feedback_scale) {
        setFeedbackScale([...res.data.feedback_scale])
      }
      if (res.data && res.data.answer_template) {
        setAnswerTemplateList([...res.data.answer_template])
      }
      setLoading(false)
    })()
  }, [modalOpen])

  let handleEditModal = () => {
    setModalOpen(!modalOpen)
  }

  let handleAddModal = () => {
    setModalOpen(!modalOpen)

  }
  return (
    <div className='feedback-management'>
      <FeedbackDashboard />
      {!loading && (
        <div className="side-bar-tab-view">
          <div className="fdbk-scale-header">
            <div className="main-header-text">
              <p>All Answer Sets</p>
            </div>
            <div>
              {' '}

              <Button
                variant="contained"
                style={{ backgroundColor: '#035642', borderColor: '4px' }}
                onClick={(e) => {
                  e.preventDefault()
                  setEditFdbkScale(false)

                  handleAddModal()
                }}>
                <span><img src="https://cdn.pegasus.imarticus.org/feedback/ic_round-plus (1).svg"></img> Create Scale</span>


              </Button>



            </div>
          </div>
          <hr></hr>
          <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid' }}>

            <table class="table table-bordered" style={{ marginBottom: "0rem" }}>
              <thead>
                <tr className='tr-layout'>
                  <th scope="col" className="table-header">S.No</th>
                  <th scope="col" className="table-header">
                    Answer Set Name
                  </th>
                  <th scope="col" className="table-header">
                    Type
                  </th>
                  <th scope="col" className="table-header">
                    Created On
                  </th>
                  <th scope="col" className="table-header">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {feedbackScale &&
                  feedbackScale.map((data, i) => {
                    return (
                      <tr key={i}>
                        <th className="table-cell">{i + 1}</th>
                        <td className="table-cell">{data.ans_set_name}</td>
                        <td className="table-cell">
                          {data.is_default ? 'Default' : 'Custom'}
                        </td>
                        <td className="table-cell">
                          {' '}
                          {moment(data.createdAt).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </td>

                        <td
                          className="table-cell"
                        // style={{
                        //   display: 'flex',
                        //   justifyContent: 'space-around',
                        // }}
                        >
                          {
                            !data.is_default ? <Button
                              style={{ width: "47px", height: "36px" }}

                              outline onClick={(e) => {
                                e.preventDefault()
                                setEditFeedbackScale({ ...data })
                                setEditFdbkScale(true)
                                handleEditModal()

                              }}>
                              <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_edit-outline.svg"></img>
                            </Button> : <Button
                              outline onClick={(e) => {
                                e.preventDefault()
                                setEditFeedbackScale({ ...data })
                                setEditFdbkScale(true)
                                handleEditModal()
                                setIsDefault(true)

                              }}
                              style={{ width: "47px", height: "36px" }}
                            >

                              <img src="https://cdn.pegasus.imarticus.org/feedback/mdi_eye-outline.svg"></img>
                            </Button>
                          }


                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <br></br>
          <br></br>

        </div>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
      {modalOpen && <>{editFdbkScale ? <AddAndEditFeedbackScale
        modalOpen={modalOpen}
        handleModal={handleEditModal}
        edit={true}
        answerSet={editFeedbackScale}
        answer_template={answerTemplateList}
        isDefault={isDefault}
      /> :
        <AddAndEditFeedbackScale
          modalOpen={modalOpen}
          handleModal={handleAddModal}
          edit={false}
          answerSet={{}}
          answer_template={answerTemplateList}
          isDefault={false}


        />}</>
      }
    </div>
  )
}

export default ManageScale
